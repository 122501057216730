import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import bannerImg from "../../../assets/img/banner/slider-01.webp";

import "./Banner.css";
import { Link } from "react-router-dom";
import { useGetBannerQuery } from "./bannerSlice";
import axios from "axios";
import { bannerDB } from "../../../rki-data/banner";
const bannerData = bannerDB;
function Banner() {
  const [data, setData] = useState();

  var settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const getData = async () => {
    try {
      const res = await axios.get(
        `https://onlineparttimejobs.in/api/banner/public`
      );
      setData(res.data);
    } catch (error) {
      alert("Server Error BannerList");
    }
  };
  useEffect(() => {
    getData();
  }, []);

  
  return (
    <>
      <section className="bannerSection">
        <Slider {...settings}>
          {bannerData &&
            bannerData.map((item) => {
              return (
                <Link to={item?.url} key={item._id}>
                  <div className="bannerItem">
                    <div
                      className="bannerDetail"
                      style={{ backgroundImage: `url(${item?.image ? item?.image.url : bannerImg})` }}
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="bannerContent">
                              <div className="title">
                                <h6>find the boundaries. push through!</h6>
                                {/* <h3>{item?.SliderTopHeading}</h3> */}
                                <h3>family jewellery collection</h3>
                                <p>
                                designer jewellery necklaces-Bracelets-Earings
                                </p>
                                <div className="percentOff">
                                  <div className="priceSection">
                                    <span className="number">
                                      {/* {item?.bottomText} */}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="shopNow">
                                <Link to="/products">shop now</Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
        </Slider>
      </section>
    </>
  );
}

export default Banner;

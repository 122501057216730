import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaMapMarkerAlt } from "react-icons/fa";
import { BsTwitter, BsInstagram, BsWhatsapp } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { MdCall } from "react-icons/md";
import { HiMail } from "react-icons/hi";
import footerlogo from "../../assets/img/logo.png";

import visa from "../../assets/img/rki/payment/visa.svg";
import master from "../../assets/img/rki/payment/master.svg";
import american from "../../assets/img/rki/payment/american.svg";

import "./Footer.css";
import axios from "axios";
import PopularSearch from "./popular-search/PopularSearch";
function Footer() {
  const [state, setState] = useState({
    about_company: "",
    office_address: "",
    location: "",
    phoneNo: "",
    email: "",
  });

  const getData = async () => {
    const res = await axios.get(
      `https://onlineparttimejobs.in/api/adminWeb_footer/get`
    );
    setState({
      about_company: res.data.about_company,
      office_address: res.data.office_address,
      location: res.data.contact_info.location,
      phoneNo: res.data.contact_info.phoneNo,
      email: res.data.contact_info.email,
    });
    window.localStorage.setItem("callNum", res.data.contact_info.phoneNo);
  };

  useEffect(() => {
    getData();
  }, []);
  const currentYear = new Date().getFullYear();
  return (
    <>
      {/* Start Footer Section */}
      <footer className="footer-section">
        <div className="footerBg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="aboutCompanyText">
                <h3>About Company</h3>
                {/* <p>{state?.about_company}</p> */}
                <p>
                  Mamastycoons has now grown to become a much loved and trusted
                  name for baby clothing, maternity essentials and children's
                  fashion. We have good collection of baby dresses, Pure cotton
                  baby clothes. With a carefully curated collection of designs
                  in peppy colours and chic combinations that are elegantly
                  stylish, our line of clothing has something for everyone's
                  liking/ parents looking for practical, well-designed outfits
                  for their children. If you need to contact us for getting our
                  products
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3 className="mb-3">Know Your Jewellery</h3>
                  <ul className="footer-quick-links sellerLists">
                    <li>
                      <Link to="/">DIAMOND GUIDE</Link>
                    </li>
                    <li>
                      <Link to="/">JEWELLERY GUIDE</Link>
                    </li>
                    <li>
                      <Link to="/">GEMSTONES GUIDE</Link>
                    </li>

                    <li>
                      <Link to="/">GOLD RATE</Link>
                    </li>
                    <li>
                      <Link to="/">DIGITAL GOLD</Link>
                    </li>
                  </ul>
                </div>

                {/* <p>{state?.office_address}</p> */}

                <ul className="footer-social">
                  <li>
                    <a
                      href="https://www.facebook.com/mamastycoons/"
                      className="facebook"
                    >
                      <FaFacebookF />
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://api.whatsapp.com/send/?phone=66362210&text&type=phone_number&app_absent=0"
                      className="whatsapp"
                    >
                      <BsWhatsapp />
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.instagram.com/mamastycoons/"
                      className="instagram"
                    >
                      <BsInstagram />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3>JEWELLERY Advantage</h3>
                </div>

                <ul className="footer-quick-links">
                  <li>
                    <Link to="/"> 15-DAY RETURNS</Link>
                  </li>
                  <li>
                    <Link to="/">FREE SHIPPING</Link>
                  </li>
                  <li>
                    <Link to="/">FINANCING OPTIONS</Link>
                  </li>
                  <li>
                    <Link to="/">OLD GOLD EXCHANGE</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3>Contact info</h3>
                </div>

                <div className="footer-info-contact">
                  <div className="topIcon">
                    <span>
                      <h3>Location</h3>
                      {/* {state?.location} */}
                      Namberdar Estate, 2nd Floor, B 98, Kailash Hills, New
                      Friends Colony, New Delhi, Delhi 110025
                    </span>
                  </div>
                </div>
                <div className="footer-info-contact">
                  <div className="topIcon">
                    <span>
                      <h3>Call Us</h3>
                      {/* <a href={`tel:${state?.phoneNo}`}> {state?.phoneNo}</a> */}
                      <a href={`tel:${state?.phoneNo}`}> +91- 9876543210</a>
                    </span>
                  </div>
                </div>

                <div className="footer-info-contact">
                  <div className="topIcon">
                    <span>
                      <h3>Email Us</h3>
                      <a href={`mailto:${state?.email}`}>
                        <span className="__cf_email__">
                          {/* {state?.email}  */}
                          example@gmail.com
                        </span>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                {/* <div className="footer-heading">
                  <h3>Newsletter</h3>
                </div>
                <p>
                  Sign up & get shopping vouchers & stay updated about latest
                  styles and exclusive promotions
                </p> */}

                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="footer-heading">
                    <h3 className="mb-3">Newsletter signup</h3>
                    <p>
                      Subscribe to our newsletters now and stay up-to-date with
                      new collections.
                    </p>
                  </div>
                </div>

                <form className="newsletter-form">
                  <input
                    type="email"
                    className="input-newsletter"
                    placeholder="Enter your email"
                    name="EMAIL"
                    required
                    autoComplete="off"
                  />
                  <button type="submit">
                    <i className="ri-arrow-right-up-fill"></i>
                    {/* Subscribe */}
                  </button>
                  <div id="validator-newsletter" className="form-result"></div>
                </form>

                <div className="paymentOption">
                  <ul>
                    <li>
                      <img src={visa} alt="Visa" className="img-fluid" />
                    </li>
                    <li>
                      <img src={master} alt="Master" className="img-fluid" />
                    </li>
                    <li>
                      <img
                        src={american}
                        alt="American"
                        className="img-fluid"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>

        <PopularSearch />
        <div className="copyright-area footerBg">
          <div className="container">
            <div className="copyright-area-content">
              <div className="copyright-left">
                <p>
                  Copyright @ {currentYear} GM. All rights reserved. |
                  Powered By:{" "}
                  <a href="https://www.abarissoftech.com/" target="_blank">
                    Abaris Softech{" "}
                  </a>
                </p>
              </div>

              <div className="copyright-right">
                <ul>
                  <li>
                    <Link to="/terms-of-use">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* End Footer Section */}
    </>
  );
}

export default Footer;

import axios from "axios";
import React, { useEffect, useState } from "react";
import login from "../../assets/img/rki/login/login-img.png";
import { FaFacebookF } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { AiOutlineGoogle } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSetLoginMutation } from "../products/productSlice";
import { Spinner } from "react-bootstrap";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
// import GoogleLogin from "react-google-login";
// import { LoginSocialFacebook } from "reactjs-social-login";
import { FcGoogle } from "react-icons/fc";
import OtpModal from "./otp-modal/OtpModal";
import VerifyOtp from "./verify-otp/VerifyOtp";

function Login() {
  const [show, setShow] = useState(false);
  const [verifyShow, setVerifyShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const handleChangeLogin = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const cloneData = { ...loginForm };
    cloneData[name] = value;
    setLoginForm(cloneData);
  };

  const [loginFormStatus, { data, isError, isSuccess, isLoading }] =
    useSetLoginMutation();

  useEffect(() => {
    if (data?.firstname) {
      setTimeout(() => {
        window.localStorage.setItem("token", data.token);
        window.localStorage.setItem("user_id", data._id);
        window.localStorage.setItem("isLogin", true);
        window.localStorage.setItem("email", data.email);
        window.localStorage.setItem("profilePic", data.image.url);
        window.localStorage.setItem(
          "userName",
          `${data.firstname} ${data.lastname}`
        );
        navigate("/");
      }, 1000);
    }
  }, [isLoading]);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    loginFormStatus(loginForm);
  };

  const [gmailData, setGailData] = useState();

  const [isGoogleLogin, seIGoogle] = useState(false);

  const sendValueGoogle = async (val) => {
    seIGoogle(true);
    try {
      const res = await axios.post(
        "https://onlineparttimejobs.in/api/socialMedia/google",
        { access_token: val?.data?.access_token }
      );

      window.localStorage.setItem("token", res.data.token);
      window.localStorage.setItem("user_id", res.data._id);
      window.localStorage.setItem("isLogin", true);
      window.localStorage.setItem("email", res.data.email);
      // window.localStorage.setItem("profilePic", data?.image?.url);
      window.localStorage.setItem(
        "userName",
        `${res?.data.firstname} ${res?.data.lastname}`
      );
      navigate("/");
    } catch (error) {}
    seIGoogle(false);
  };

  useEffect(() => {
    if (gmailData) {
      sendValueGoogle(gmailData);
    }
  }, [gmailData]);

  // console.log(gmailData);

  const responseFacebook = (response) => {
    console.log(response);
  };

  return (
    <>
      <Helmet>
        <title>Login | Fertilizer Multi Vendor</title>
        <meta
          name="keyword"
          content="Fertilizer, Agricultural, Seeds, Machinery, Nutrition"
        />
        <meta
          name="description"
          content="Buy Agricultural Products and Machinery Online at ETG. We Offering broad range of Seeds, Plant Nutrition, Plant Protection and Agri Implements."
        />
      </Helmet>
      {/* <CustomToaster color={showTaoster.color} show={showTaoster.show} setShow={handleToaster} message={showTaoster.message} position="bottom-end" delay={10000} /> */}
      <div className="registrationDetail">
        <div className="container">
          <div className="registrationInfo">
            <div className="registerContent">
              {isGoogleLogin && (
                <div className="preloaderCount">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
              <div className="contentHeader">
                <h3>Stay Updated on your professional world</h3>
                <p>Sign in with your mobile number to get started</p>
              </div>
              <div className="contentFooter">
                <img src={login} alt="Login" className="img-fluid" />
              </div>
            </div>
            <div className="registerForm">
              <h4 className="mb-4">Login to your account.</h4>
              <form className="registerFormField " onSubmit={handleLoginSubmit}>
                <div className="mb-3">
                  <input
                    type="email"
                    placeholder="email"
                    className="form-control"
                    autoComplete="off"
                    name="email"
                    onChange={handleChangeLogin}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    placeholder="Password"
                    className="form-control"
                    autoComplete="off"
                    name="password"
                    onChange={handleChangeLogin}
                  />
                </div>
                <div className="form-check mb-3 forgotInfo">
                  <div className="rememberText">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label agreeCheck"
                      htmlFor="flexCheckDefault"
                    >
                      Remember Me
                    </label>
                  </div>
                  <div className="forgotText">
                    <Link to="/reset">Forgot password?</Link>
                  </div>
                </div>
                {isError && <p className="text-danger mb-1">Login Fail ! </p>}
                {isSuccess && (
                  <p className="text-green mb-1">login Successfully !</p>
                )}
                <button
                  className="btn btn-primary createAccount"
                  type="submit"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  login
                  {isLoading && (
                    <Spinner style={{ marginLeft: "7px" }} animation="border" />
                  )}
                </button>
                <div className="form-group mt-2">
                  <button
                    type="button"
                    className="btn btn-warning otpButton"
                    onClick={handleShow}
                  >
                    LOGIN WITH OTP
                  </button>
                </div>
                <OtpModal
                  data={{ handleShow, show, setVerifyShow, handleClose }}
                />
                <VerifyOtp data={{ verifyShow, setVerifyShow }} />
              </form>
              <div className="joinWith">
                <span>or login with</span>
              </div>
              <div className="connectWith">
                <ul>
                  <li>
                    {/* <a href="#"
                      appId="1007501343742455"
                      autoLoad={false}
                      // fields="name,email,picture"
                      callback={responseFacebook}
                      className="facebook">
                      <FaFacebookF />
                    </a> */}

                    <LoginSocialFacebook
                      client_id="1007501343742455"
                      discoveryDocs="claims_supported"
                      access_type="offline"
                      onResolve={(provider, data) => {
                        // setGailData(provider)
                        console.log(provider);
                      }}
                      onReject={(err) => {
                        console.log(err);
                      }}
                    >
                      <a href="#" className="facebook">
                        <FaFacebookF />
                      </a>
                    </LoginSocialFacebook>

                    {/* <FacebookLogin
                      appId="1007501343742455"
                      autoLoad={false}
                      // fields="name,email,picture"
                      callback={responseFacebook}
                      className="facebook"
                    /> */}

                    {/* <LoginSocialFacebook appId="1007501343742455"
                      onResolve={(res) => {
                        console.log(res);
                      }}
                      onReject={(rej) => {
                        console.log(rej);
                      }}
                    >
                    </LoginSocialFacebook> */}
                  </li>

                  <li>
                    <a href="#" className="twitter">
                      <BsTwitter />
                    </a>
                  </li>

                  <li>
                    <LoginSocialGoogle
                      client_id="29276214138-s764o2gvrj925kgiii029stgapt5raae.apps.googleusercontent.com"
                      scope="email"
                      discoveryDocs="claims_supported"
                      access_type="offline"
                      onResolve={(provider, data) => {
                        setGailData(provider);
                      }}
                      onReject={(err) => {
                        console.log(err);
                      }}
                    >
                      <a href="#" className="google">
                        <FcGoogle />
                      </a>
                    </LoginSocialGoogle>
                  </li>
                </ul>
              </div>
              <div className="alreadyAccount">
                <p>Don't have an account?</p>
                <Link to="/registration">Register Now</Link>
              </div>
            </div>
          </div>

          
        </div>
      </div>
    </>
  );
}

export default Login;
